import PropTypes from 'prop-types';
import { useMemo, useState, Fragment } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Table, TableBody, TableContainer, TableCell, TableHead, TableRow, Stack, Box, Divider, Paper
} from '@mui/material';
import {
  useReactTable, getCoreRowModel, getPaginationRowModel, flexRender, getSortedRowModel,
  getExpandedRowModel, getFilteredRowModel, getFacetedRowModel, getFacetedMinMaxValues,
  getFacetedUniqueValues
} from '@tanstack/react-table';
import PDFExport from '@/from-theme/components/third-party/react-table/PDFExport/PDFExport';
import { CSVExport, TablePagination, HeaderSort, SelectColumnVisibility, 
  DebouncedInput } from '@/from-theme/components/third-party/react-table';
import { EmptyTable } from '@/from-theme/components/third-party/react-table';
import TableLoader from './components/table-loader/table-loader';
import TableError from './components/table-error/table-error';
import { apiStatuses } from '@/store/api/constants';  
import { dateString, getStringData } from './helpers/helpers';
import "./TableView.scss";
import MainCard from '@/from-theme/components/MainCard';
import ScrollX from '@/from-theme/components/ScrollX';
import { rankItem } from '@tanstack/match-sorter-utils';
import { useIntl } from 'react-intl';

export const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta(itemRank);
  return itemRank.passed;
};

export default function TableView({
  data, columns: columnsLocale, top, title, actionButton, reportFileName = "", apiStatus,
  apiErrorMsg, renderRowTable, isTableDetails, rowSelection = {}, setRowSelection, initialPageSize,
  stringInExport = []
}) {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const backColor = alpha(theme.palette.primary.lighter, 0.1);

  const columns = useMemo(() => {
    const cols = [];
    const hiddenCols = {};
    
    columnsLocale.forEach((item) => {
      cols.push({
        ...item,
        header: typeof item.header === "string" ? formatMessage({ id: item.header }) : item.header
      });

      if (item?.hideColumn) {
        hiddenCols[item.accessorKey] = false;
      }
    });

    return { cols, hiddenCols };
  }, [columnsLocale, formatMessage]);

  const [sorting, setSorting] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState(columns.hiddenCols);
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    data,
    columns: columns.cols,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    ...(setRowSelection ? {
      enableRowSelection: true,
      onRowSelectionChange: setRowSelection,
    } : {}),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    globalFilterFn: fuzzyFilter
  });

  const selectedHeaders = useMemo(() => {
    return table.getAllColumns().map(column => ({
      label: typeof column.columnDef.header === 'string' ? column.columnDef.header : '#',
      key: column.columnDef.accessorKey
    }));
  }, [table]);


const exportData = useMemo(() => {
  const rows = table.getSortedRowModel().rows;

  const formattedData = rows.map(row => {
    const rowData = row.getAllCells().reduce((acc, cell) => {
      const key = cell.column.columnDef.accessorKey;
      const header = cell.column.columnDef.header;
      const value = cell.getValue();
      if(header === 'S.No' || header === 's.no'){
        acc[key] = row?.index + 1;
      }
      else if (key === 'createdAt' || key === 'updatedAt') {
        acc[key] = dateString(value); // Format date for display
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
    return rowData;

  });

  const stringData = getStringData(formattedData, stringInExport);

  return stringData;
}, [stringInExport, table]);

  return (
    <MainCard
      title={title || "hidden"}
      content={false}
      className={!title ? "hide-main-card-title table-main-card" : "table-main-card"}
      secondary={
        <>
          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2 }}>
            <DebouncedInput
              value={globalFilter ?? ''}
              onFilterChange={(value) => setGlobalFilter(String(value))}
              placeholder={`Search ${data.length} records...`}
            />
            <SelectColumnVisibility
              {...{
                getVisibleLeafColumns: table.getVisibleLeafColumns,
                getIsAllColumnsVisible: table.getIsAllColumnsVisible,
                getToggleAllColumnsVisibilityHandler: table.getToggleAllColumnsVisibilityHandler,
                getAllColumns: table.getAllColumns
              }}
            />
            <CSVExport
              data={exportData}
              headers={selectedHeaders}
              filename={reportFileName}
            />
            <PDFExport
              data={exportData}
              headers={selectedHeaders}
              filename={reportFileName}
            />
            {actionButton && actionButton}
          </Stack>
        </>
      }
    >
      <ScrollX>
        <Stack>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                {table.getHeaderGroups().map(headerGroup => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <TableCell
                        key={header.id}
                        {...header.column.columnDef.meta}
                        onClick={header.column.getToggleSortingHandler()}
                        className={header.column.getCanSort() ? 
                          'cursor-pointer prevent-select' : ''}
                      >
                        {header.isPlaceholder ? null : (
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Box>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </Box>
                            {header.column.getCanSort() && <HeaderSort column={header.column} />}
                          </Stack>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                <>
                  {(apiStatus === apiStatuses.succeeded || apiStatus === undefined) && (
                    table.getRowModel().rows.length > 0 ? (
                      table.getRowModel().rows.map(row => (
                        <Fragment key={`table-view${row.id}`}>
                          <TableRow>
                            {row.getVisibleCells().map(cell => (
                              <TableCell key={cell.id} {...cell.column.columnDef.meta}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </TableCell>
                            ))}
                          </TableRow>
                          {row.getIsExpanded() && renderRowTable && (
                            !isTableDetails ? renderRowTable(row) : (
                              <TableRow
                                sx={{
                                  bgcolor: backColor,
                                  '&:hover': { bgcolor: `${backColor} !important` }
                                }}
                              >
                                <TableCell colSpan={row.getVisibleCells().length}>
                                  {renderRowTable(row)}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={table.getAllColumns().length}>
                          <EmptyTable msg={formatMessage({ id: "no-data" })} />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                  {apiStatus === apiStatuses.pending && (
                    <TableLoader colSpan={table.getAllColumns().length} />
                  )}
                  {apiStatus === apiStatuses.rejected && (
                    <TableError colSpan={table.getAllColumns().length} message={apiErrorMsg} />
                  )}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          {!top && (
            <>
              <Divider />
              <Box sx={{ p: 2 }}>
                <TablePagination
                  initialPageSize={initialPageSize}
                  {...{
                    setPageSize: table.setPageSize,
                    setPageIndex: table.setPageIndex,
                    getState: table.getState,
                    getPageCount: table.getPageCount
                  }}
                />
              </Box>
            </>
          )}
        </Stack>
      </ScrollX>
    </MainCard>
  );
}

TableView.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  top: PropTypes.bool,
  title: PropTypes.string,
  actionButton: PropTypes.node,
  showDropdown: PropTypes.bool,
  reportFileName: PropTypes.string,
  apiStatus: PropTypes.string,
  apiErrorMsg: PropTypes.string,
  renderRowTable: PropTypes.func,
  isTableDetails: PropTypes.bool,
  rowSelection: PropTypes.object,
  setRowSelection: PropTypes.func,
  initialPageSize: PropTypes.number,
  stringInExport: PropTypes.array
};
