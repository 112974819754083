// eslint-disable-next-line no-undef
// export const baseURL = process.env.REACT_APP_API_BASE_URL;
export const aboutus ='https://www.nagamills.com/aboutus.html'
//export const oyaloUrl = 'https://oyalopro.nagamills.com/op_api/api/'
export const oyaloUrl = 'http://127.0.0.1:8000/api/'
//export const apiBaseUrl ='http://127.0.0.1:8000/api/'
export const apiBaseUrl = 'https://digipro.nagamills.com/dp_api/public/api/'
//export const apiBaseUrl = 'https://digiprouat.nagamills.com/dp_api/public/api/'




