import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { fetchData, clearMultipleData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { initialValues } from '../constants/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';

function useCreateEditZoneMaster(activeEditId) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const saveZoneMasterStatus = getStatus(state, apiKeys.saveZoneMaster, '');
  const updateZoneMasterStatus = getStatus(state, apiKeys.updateZoneMaster, '');
  const saveZoneMasterLoading = apiStatuses.pending === saveZoneMasterStatus;
  const getEditZoneMasterData = getData(state, apiKeys.getEditZoneMaster, {});
  const getEditZoneMasterStatus = getStatus(state, apiKeys.getEditZoneMaster, '');
  const getEditZoneMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditZoneMaster,
    formatMessage({ id: 'failed-to-get-selected-zone-details' })
  );

  const validationSchema = yup.object({
    groupName: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    stages: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    rules: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    offers: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (activeEditId) {
        updateZoneMaster(values);
      } else {
        saveZoneMaster(values);
      }
    }
  });

  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  const getEditZoneMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditZoneMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditZoneMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-zone-details' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditZoneMaster();
    }
  }, [activeEditId, getEditZoneMaster]);

  useEffect(() => {
    if (getEditZoneMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditZoneMasterData));
    }
  }, [getEditZoneMasterStatus]);

  const saveZoneMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.saveZoneMaster,
          method: apiMethods.POST,
          key: apiKeys.saveZoneMaster,
          successMsg: formatMessage({ id: 'zone-master-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'zone-master-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updateZoneMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updateZoneMaster,
          method: apiMethods.PUT,
          key: apiKeys.updateZoneMaster,
          successMsg: formatMessage({ id: 'zone-master-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'zone-master-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.saveZoneMaster, apiKeys.getEditZoneMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      formik,
      values: formik.values,
      saveZoneMasterStatus,
      updateZoneMasterStatus,
      saveZoneMasterLoading,
      getEditZoneMasterData,
      getEditZoneMasterStatus,
      getEditZoneMasterErrMsg
    }),
    [formik, saveZoneMasterStatus, updateZoneMasterStatus, saveZoneMasterLoading,
       getEditZoneMasterData, getEditZoneMasterStatus, getEditZoneMasterErrMsg]
  );
}

export default useCreateEditZoneMaster;
