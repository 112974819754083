import { useFormik } from 'formik';
import * as yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { initialValues } from '../constants/constants';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getErrorMessage, getStatus } from '@/store/slices/helpers/helpers';
import { clearMultipleData, fetchData } from '@/store/slices/fetch-slice';
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '@/store/api/constants';
import { getFormattedPayload, getFormattedResponse } from '@/common/helpers/helpers';

function useCreateEditMaterialMaster({ activeEditId, getCategoiesByBrand, getProductByCategory, 
  getDropdownList }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const [alertVisible, setAlertVisible] = useState(false);

  const saveMaterialMasterStatus = getStatus(state, apiKeys.saveMaterialMaster, '');
  const updateMaterialMasterStatus = getStatus(state, apiKeys.updateMaterialMaster, '');
  const getMaterialDataFromSapStatus = getStatus(state, apiKeys.getMaterialDataFromSap, '');
  const saveMaterialMasterLoading = apiStatuses.pending === saveMaterialMasterStatus;
  const getEditMaterialMasterData = getData(state, apiKeys.getEditMaterialMaster, {});
  const getMaterialDataFromSapData = getData(state, apiKeys.getMaterialDataFromSap, {});
  const getEditMaterialMasterStatus = getStatus(state, apiKeys.getEditMaterialMaster, '');
  const getEditMaterialMasterErrMsg = getErrorMessage(
    state,
    apiKeys.getEditMaterialMaster,
    formatMessage({ id: 'failed-to-get-selected-material-details' })
  );

  const validationSchema = yup.object({
    materialTypeId: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    categoryId: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    brandId: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    productId: yup.object().required(formatMessage({ id: 'this-field-is-required' })),
    materialCode: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    materialName: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    buom: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    grossWeight: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    netWeight: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    auom: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    conversionFactor: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    controlCode: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    deliveryUnit: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    sku: yup.string().required(formatMessage({ id: 'this-field-is-required' })),
    deletionIndicator: yup.string().nullable(),
    activeStatus: yup.boolean().required(formatMessage({ id: 'this-field-is-required' }))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  const { values } = formik;
  const { materialCode, brandId, categoryId, activeStatus } = values;


  formik.fields = validationSchema?.fields;
  formik.schema = validationSchema;

  useEffect(() => {
    if (materialCode !== null && materialCode !== undefined) {
      const trimmedMaterialCode = materialCode.trim().toUpperCase();
      if (trimmedMaterialCode !== '') {
        formik.setFieldValue('materialCode', trimmedMaterialCode);
      }
    }
    if (activeStatus === undefined) {
      formik.setFieldValue('activeStatus', 1);
    }
  }, [materialCode, activeStatus]);
  

  const getMaterialDataFromSap = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getMaterialDataFromSap(materialCode),
        method: apiMethods.GET,
        key: apiKeys.getMaterialDataFromSap,
        successMsg: formatMessage({ id: 'data-fetched-successfully-from-sap' }),
        failureMsg: formatMessage({ id: 'data-fetch-failed-from-sap' })
      })
    );
  }, [dispatch, formatMessage, materialCode]);

  const onSearch = useCallback(() => {
    if (materialCode !== '') {
      getMaterialDataFromSap(materialCode);
    } else {
      formatMessage({ id: 'failed-to-get-selected-material-details' });
    }
  }, [materialCode, getMaterialDataFromSap, formatMessage]);

  const getEditMaterialMaster = useCallback(() => {
    dispatch(
      fetchData({
        url: apiUrls.getEditMaterialMaster(activeEditId),
        method: apiMethods.GET,
        key: apiKeys.getEditMaterialMaster,
        failureMsg: formatMessage({ id: 'failed-to-get-selected-material-details' })
      })
    );
  }, [dispatch, formatMessage, activeEditId]);

  useEffect(() => {
    if (activeEditId) {
      getEditMaterialMaster(activeEditId);
    }
    getDropdownList(37);

  }, [activeEditId, getEditMaterialMaster, getDropdownList]);

  useEffect(() => {
    if (brandId) {
      const bndId = brandId?.id;
      getCategoiesByBrand(bndId);
    }
    if (categoryId) {
      const catId = categoryId?.id;
      getProductByCategory(catId);
    }
  }, [brandId, categoryId, getCategoiesByBrand, getProductByCategory]);

  const onChangeBrand = useCallback(() => {
    // clear data
    formik.setFieldValue('categoryId', undefined);
    formik.setFieldValue('productId', undefined);
  }, [formik]);

  const onChangeCategory = useCallback(() => {
    // clear data
    formik.setFieldValue('productId', undefined);
  }, [formik]);

  useEffect(() => {
    if (getEditMaterialMasterStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getEditMaterialMasterData));
    } else if (getMaterialDataFromSapStatus === apiStatuses.succeeded) {
      formik.setValues(getFormattedResponse(getMaterialDataFromSapData));
    }
  }, [getEditMaterialMasterStatus, getMaterialDataFromSapStatus]);



  const saveMaterialMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.saveMaterialMaster,
          method: apiMethods.POST,
          key: apiKeys.saveMaterialMaster,
          successMsg: formatMessage({ id: 'Material-master-is-saved-successfully' }),
          failureMsg: formatMessage({ id: 'Material-master-is-failed-to-save' }),
          body: getFormattedPayload(values)
        })
      );
    },
    [dispatch, formatMessage]
  );

  const updateMaterialMaster = useCallback(
    (values) => {
      dispatch(
        fetchData({
          url: apiUrls.updateMaterialMaster,
          method: apiMethods.PUT,
          key: apiKeys.updateMaterialMaster,
          successMsg: formatMessage({ id: 'Material-master-is-updated-successfully' }),
          failureMsg: formatMessage({ id: 'Material-master-is-failed-to-update' }),
          body: getFormattedPayload({ ...values, id: activeEditId })
        })
      );
    },
    [dispatch, formatMessage, activeEditId]
  );

  const handleSave = useCallback(async () => {
    if (Object.keys(formik.errors).length === 0) {
      if (activeEditId) {
        await updateMaterialMaster(values);
      } else {
        await saveMaterialMaster(values);
      }
    } else{
      setAlertVisible(true);
    } 
  }, [formik, activeEditId, saveMaterialMaster, updateMaterialMaster, values]);

  useEffect(
    () => () => {
      dispatch(
        clearMultipleData({
          key: [
            apiKeys.divisionMaster,
            apiKeys.getDepartmentOptions,
            apiKeys.saveMaterialMaster,
            apiKeys.updateMaterialMaster,
            apiKeys.getEditMaterialMaster,
            apiKeys.getMaterialDataFromSap
          ]
        })
      );
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      formik,
      onSearch,
      handleSave,
      alertVisible,
      onChangeBrand,
      onChangeCategory,
      values: formik.values,
      saveMaterialMasterStatus,
      updateMaterialMasterStatus,
      saveMaterialMasterLoading,
      getEditMaterialMasterData,
      getEditMaterialMasterStatus,
      getEditMaterialMasterErrMsg,
      getMaterialDataFromSapStatus
    }),
    [
      formik,
      onSearch,
      handleSave,
      alertVisible,
      onChangeBrand,
      onChangeCategory,
      saveMaterialMasterStatus,
      updateMaterialMasterStatus,
      saveMaterialMasterLoading,
      getEditMaterialMasterData,
      getEditMaterialMasterStatus,
      getEditMaterialMasterErrMsg,
      getMaterialDataFromSapStatus
    ]
  );
}

export default useCreateEditMaterialMaster;
