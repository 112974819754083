import { BookOutlined, UserOutlined } from '@ant-design/icons';
import EventGroups from '../components/event-groups/event-groups';
import EventActivityGroups from '../components/event-activity-groups/event-activity-groups';
import EventActivity from '../components/event-activity/event-activity';
import VerifyAccess from '@/common/components/verify-access/verify-access';
import { requestedFeaturesAll, requestedMenuAll } from '@/from-theme/routes/constants/menuAccess';
const { zoneTabMasterKey, subZoneTabMasterKey } = requestedMenuAll;
const { readKey } = requestedFeaturesAll;

// tabName is locale id
export const eventMasterTabs = [
  {
    id: 'event-groups',
    tabName: 'event-groups',
    icon: <UserOutlined />,
    tabContent: (
      <VerifyAccess requestedMenu={zoneTabMasterKey} requestedFeature={readKey}>
        <EventGroups />
      </VerifyAccess>
    )
  },
  {
    id: 'event-activity-groups',
    tabName: 'event-activity-groups',
    icon: <BookOutlined />,
    tabContent: (
        <VerifyAccess requestedMenu={subZoneTabMasterKey} requestedFeature={readKey}>
          <EventActivityGroups />
        </VerifyAccess>
      )
  },
  {
    id: 'event-activity',
    tabName: 'event-activity',
    icon: <BookOutlined />,
    tabContent: (
        <VerifyAccess requestedMenu={subZoneTabMasterKey} requestedFeature={readKey}>
          <EventActivity />
        </VerifyAccess>
      )
  }
];
