import React from 'react';
import TableView from '@/common/components/TableView/CustomTableView';
import { Grid, Button } from '@mui/material';
import { PlusOutlined } from '@ant-design/icons';
import { columns, reportFileName } from './constants/constants';
import CreateEditPrimaryCustomer from 
'./create-edit-primary-customer-master/create-edit-primary-customer-master';
import ModalDialog from '@/common/components/modal-dialog/modal-dialog';
import { FormattedMessage } from 'react-intl';
import useOnActions from './hooks/useOnActions';
import AlertDialog from '@/common/components/alert-dialog/alert-dialog';
import usePrimaryCustomer from './hooks/usePrimaryCustomerMaster';
import VerifyAccess from '@/common/components/verify-access/verify-access';
import { requestedMenuAll, requestedFeaturesAll } from '@/from-theme/routes/constants/menuAccess';
import { isUserHasAccess } from '@/from-theme/utils/route-guard/helpers/helpers';
import useAuth from '@/from-theme/hooks/useAuth';
import MainCard from '@/from-theme/components/MainCard';
import Dropdown from '@/common/components/dropdown/dropdown';
import useGetStateOptions from '@/common/hooks/useGetStateMaster';
import useGetDistrictMasterByState from '@/common/hooks/useGetDistrictByState';
import useGetCityMasterByDistrict from '@/common/hooks/useGetCityByDistrict';

const { primaryCustTabMasterKey } = requestedMenuAll;
const { createKey, updateKey, deleteKey } = requestedFeaturesAll;

export default function PrimaryCustomer() {
  const { getStateOptions, getStateLoading } = useGetStateOptions();
  const {
    getDistrictsByStateOptions: districtsData,
    getDistrictsByState: getDistrict,
    getDistrictsByStateLoading: districtsLoading
  } = useGetDistrictMasterByState('stateId');
  const {
    getCitysByDistrictOptions: citiesData,
    getCitysByDistrict: getCity,
    getCitysByDistrictLoading: citiesLoading
  } = useGetCityMasterByDistrict('district');
  const {
    primaryCustomerMasterData,
    isCreateEditModalOpen,
    setIsCreateEditModalOpen,
    onRowEdit,
    activeEditId,
    closeModal,
    primaryCustomerMasterStatus,
    primaryCustomerMasterErrMsg,
    values,
    formik
  } = usePrimaryCustomer({getDistrict, getCity});

  const { stateId, districtId, cityId } = values;
  const { userData } = useAuth();
  const entitlement = userData?.entitlement || {};

  const isUpdateDeleteAccess =
    isUserHasAccess(primaryCustTabMasterKey, updateKey, entitlement) || 
    isUserHasAccess(primaryCustTabMasterKey, deleteKey, entitlement);
  const { onRowDelete, deletePrimaryCustomerStatus, isAlertDialog, onCloseAlert, 
    onAgreeAlert } = useOnActions();

  const actionButton = (
    <VerifyAccess requestedMenu={primaryCustTabMasterKey} requestedFeature={createKey}>
      <Button variant="contained" startIcon={<PlusOutlined />} onClick={() => 
        setIsCreateEditModalOpen(true)}>
        <FormattedMessage id="add-primary-customer" />
      </Button>
    </VerifyAccess>
  );

  return (
    <MainCard>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              options={getStateOptions}
              loading={getStateLoading}
              placeholder="state-name"
              inputLabel="state-name"
              value={stateId}
              formik={formik}
              name="stateId"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              options={districtsData}
              loading={districtsLoading}
              placeholder="district-name"
              inputLabel="district-name"
              value={districtId}
              formik={formik}
              name="districtId"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              options={citiesData}
              loading={citiesLoading}
              placeholder="city-name"
              inputLabel="city-name"
              value={cityId}
              formik={formik}
              name="cityId"
              isCheckBox
              multiple
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} style={{ paddingTop: '45px' }}>
            <Button type="submit" variant="contained" size="small">
              <FormattedMessage id="search" />
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableView
            data={primaryCustomerMasterData}
            columns={columns({ onRowEdit, onRowDelete, deletePrimaryCustomerStatus, 
              isUpdateDeleteAccess })}
            actionButton={actionButton}
            reportFileName={reportFileName}
            apiStatus={primaryCustomerMasterStatus}
            apiErrorMsg={primaryCustomerMasterErrMsg}
            initialPageSize={50}
            stringInExport={['activeStatus']}
          />
          {isCreateEditModalOpen && (
            <ModalDialog
              closeModal={() => closeModal()}
              modalTitle={!activeEditId ? 'create-primary-customer-master' : 
                'edit-primary-customer-master'}
              fullScreen
            >
              <CreateEditPrimaryCustomer closeModal={() => closeModal()} 
              activeEditId={activeEditId} />
            </ModalDialog>
          )}
          {isAlertDialog && <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />}
        </Grid>
      </Grid>
    </MainCard>
  );
}
