import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import Dropdown from "@/common/components/dropdown/dropdown";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import { apiStatuses } from "@/store/api/constants";
import useCreateEditEventActivity from "./hooks/useCreateEditEventActivity";
import useGetZoneOptions from "@/common/hooks/useGetZoneMaster";
import useGetDistrictOptions from "@/common/hooks/useGetDistrictMaster";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";
import PropTypes from "prop-types";


function CreateEditEventActivity({closeModal, activeEditId}) {

  const { getZoneOptions, getZoneLoading } = useGetZoneOptions();
  const { getDistrictOptions, getDistrictLoading } = useGetDistrictOptions();
  const { values, formik, getEditSubzoneMasterStatus, saveSubzoneMasterStatus,
    updateSubzoneMasterStatus, getEditSubzoneMasterErrMsg } = 
    useCreateEditEventActivity(activeEditId);

  const isPending = [saveSubzoneMasterStatus, updateSubzoneMasterStatus]
  .includes(apiStatuses.pending);

  const {
    activityName, eventActivityGroup, responsiblePerson, activeStatus,
  } = values;

  return (
    <>
      {
        getEditSubzoneMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }
      {getEditSubzoneMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditSubzoneMasterErrMsg} />
      )}
      {(getEditSubzoneMasterStatus === apiStatuses.succeeded || !activeEditId) &&
    <form onSubmit={formik.handleSubmit}>
      <DialogContent dividers>
        <Grid container spacing={1.25} style={{ paddingBottom: '225px' }}>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Dropdown
              options={getZoneOptions}
              loading={getZoneLoading}
              placeholder="event-activity-group"
              inputLabel="event-activity-group"
              value={eventActivityGroup}
              formik={formik}
              name="eventActivityGroup"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <TextInput
              placeholder="activity-name" size="small" fullWidth
              value={activityName}
              inputLabel="activity-name"
              formik={formik}
              name="activityName"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Dropdown
              options={getDistrictOptions}
              loading={getDistrictLoading}
              placeholder="responsible-person"
              inputLabel="responsible-person"
              value={responsiblePerson}
              formik={formik}
              name="responsiblePerson"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <SwitchInput
              inputLabel="activeStatus"
              leftLabel="inactive"
              rightLabel="active"
              value={activeStatus}
              formik={formik}
              name="activeStatus"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending}
         sx={{ mr: 1 }}>
          <FormattedMessage id={"cancel"} />
        </Button>
        <AnimateButton>
          <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
            <FormattedMessage id={"save"} />
          </Button>
        </AnimateButton>
      </DialogActions>
    </form>
     }
     </>
  )
}

CreateEditEventActivity.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditEventActivity.defaultProps = {
  activeEditId: 0,
};

export default CreateEditEventActivity;