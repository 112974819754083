import React from "react";
import {
  Button, DialogActions, DialogContent, Grid
} from '@mui/material';
import TextInput from "@/common/components/text-input/text-input";
import Dropdown from "@/common/components/dropdown/dropdown";
import SwitchInput from "@/common/components/switch-input/switch-input";
import AnimateButton from "@/from-theme/components/@extended/AnimateButton";
import { FormattedMessage } from 'react-intl';
import { apiStatuses } from "@/store/api/constants";
import useCreateEditEventActivityGroups from "./hooks/useCreateEditEventActivityGroups";
import useGetDistrictOptions from "@/common/hooks/useGetDistrictMaster";
import Spinner from "@/common/components/spinner/spinner";
import ErrorInPage from "@/common/components/error-in-page/error-in-page";
import PropTypes from "prop-types";


function CreateEditActivityGroup({
  closeModal, activeEditId, 
}) {
  const { getDistrictOptions, getDistrictLoading } = useGetDistrictOptions();
  const { values, formik, getEditSubzoneMasterStatus, saveSubzoneMasterStatus,
    updateSubzoneMasterStatus, getEditSubzoneMasterErrMsg } = 
    useCreateEditEventActivityGroups(activeEditId);

  const isPending = [saveSubzoneMasterStatus, updateSubzoneMasterStatus]
  .includes(apiStatuses.pending);

  const {
     activityGroupName, department, designation, user, activeStatus
  } = values;

  return (
    <>
      {
        getEditSubzoneMasterStatus === apiStatuses.pending && activeEditId
        && <Spinner />
      }

      {getEditSubzoneMasterStatus === apiStatuses.rejected && activeEditId && (
        <ErrorInPage message={getEditSubzoneMasterErrMsg} />
      )}

      {(getEditSubzoneMasterStatus === apiStatuses.succeeded || !activeEditId) &&
    <form onSubmit={formik.handleSubmit}>
      <DialogContent dividers>
        <Grid container spacing={1.25} style={{ paddingBottom: '225px' }}>
         
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <TextInput
              placeholder="activity-group-name" size="small" fullWidth
              value={activityGroupName}
              inputLabel="activity-group-name"
              formik={formik}
              name="activityGroupName"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Dropdown
              options={getDistrictOptions}
              loading={getDistrictLoading}
              placeholder="department"
              inputLabel="department"
              value={department}
              formik={formik}
              name="department"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Dropdown
              options={getDistrictOptions}
              loading={getDistrictLoading}
              placeholder="designation"
              inputLabel="designation"
              value={designation}
              formik={formik}
              name="designation"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Dropdown
              options={getDistrictOptions}
              loading={getDistrictLoading}
              placeholder="user"
              inputLabel="user"
              value={user}
              formik={formik}
              name="user"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <SwitchInput
              inputLabel="activeStatus"
              leftLabel="inactive"
              rightLabel="active"
              value={activeStatus}
              formik={formik}
              name="activeStatus"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeModal} variant="outlined" disabled={isPending}
         sx={{ mr: 1 }}>
          <FormattedMessage id={"cancel"} />
        </Button>
        <AnimateButton>
          <Button type="submit" variant="contained" disabled={isPending} sx={{ mr: 1 }}>
            <FormattedMessage id={"save"} />
          </Button>
        </AnimateButton>
      </DialogActions>
    </form>
     }
     </>
  )
}

CreateEditActivityGroup.propTypes = {
  closeModal: PropTypes.func.isRequired,
  activeEditId: PropTypes.bool,
};

CreateEditActivityGroup.defaultProps = {
  activeEditId: 0,
};

export default CreateEditActivityGroup;