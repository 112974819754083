
export const requestedMenuAll = {
    dashboardKey: "DASHBOARD",
    companyMasterKey: "COMPANY_MASTER",
    glMasterKey: "GL_MASTER",
    accommodationTabMasterKey: "ACCOMMODATION_TAB_MASTER",
    allowanceTabMasterKey: "ALLOWANCE_TAB_MASTER",
    appTrackReportKey: "APP_TRACK_REPORT",
    energyPriceTabMasterKey: "ENERGY_PRICE_TAB_MASTER",
    energyTypeTabMasterKey: "ENERGY_TYPE_TAB_MASTER",
    bankMasterKey: "BANK_MASTER",
    customerMasterKey: "CUSTOMER_MASTER",
    primaryCustTabMasterKey: "PRIMARYCUSTOMER_TAB_MASTER",
    secondCustTabMasterKey: "SECONDCUSTOMER_TAB_MASTER",
    planApplicableMasterKey: "PLAN_APPLICABLE_MASTER",
    competitorOffersKey: "COMPETITOR_PRODUCTS_MASTER",
    ifdCompetitorMasterKey: "IFD_COMPETITOR_MASTER",
    fdCompetitorMasterKey: "FD_COMPETITOR_MASTER",
    employeeMasterKey: "EMPLOYEE_MASTER",
    userMasterKey: "USER_MASTER",
    divisionMasterKey: "DIVISION_MASTER",
    plantMasterKey: "PLANT_MASTER",
    planValidationKey: "PLAN_VALIDATION",
    planValidationManagerKey: "PLAN_VALIDATION_MANAGER",
    planDeviationBOKey: "PLAN_DEVIATION_BACKOFFICE",
    planDeviationManagerKey: "PLAN_DEVIATION_MANAGER",
    approvalFoodsMasterKey: "APPROVAL_PLAN_FOODS",
    approvalClaimBOKey: "CLAIM_VALIDATION_BACKOFFICE",
    prospectValidationMasterKey: "PROSPECT_VALIDATION",
    shiftMasterKey: "SHIFT_MASTER",
    outletMasterKey: "OUTLET_MASTER",
    prospectMasterKey: "PROSPECT_MASTER",
    venueMasterKey: "VENUE_MASTER",
    locationMasterKey: "LOCATION_MASTER",
    countryTabMasterKey: "COUNTRY_TAB_MASTER",
    stateTabMasterKey: "STATE_TAB_MASTER",
    districtTabMasterKey: "DISTRICT_TAB_MASTER",
    cityTabMasterKey: "CITY_TAB_MASTER",
    areaTabMasterKey: "AREA_TAB_MASTER",
    roleMasterKey: "ROLE_MASTER",
    departmentTabMasterKey: "DEPARTMENT_TAB_MASTER",
    designationTabMasterKey: "DESIGNATION_TAB_MASTER",
    gradeTabMasterKey: "GRADE_TAB_MASTER",
    gradeListTabMasterKey: "GRADE_LIST_TAB_MASTER",
    roleTabMasterKey: "ROLE_TAB_MASTER",
    menuMasterKey: "MENU_MASTER",
    menuTabMasterKey: "MENU_TAB_MASTER",
    menuListTabMasterKey: "MENULIST_TAB_MASTER",
    menuAccessTabMasterKey: "MENU_ACCESS_TAB_MASTER",
    productMasterKey: "PRODUCT_MASTER",
    brandTabMasterKey: "BRAND_TAB_MASTER",
    categoryTabMasterKey: "CATEGORY_TAB_MASTER",
    productTabMasterKey: "PRODUCT_TAB_MASTER",
    materialTabMasterKey: "MATERIAL_TAB_MASTER",
    claimMasterKey: "CLAIM_MASTER",
    dropdownMasterKey: "DROPDOWN_MASTER",
    dropdownTabMasterKey: "DROPDOWN_TAB_MASTER",
    dropdownListTabMasterKey: "DROPDOWN_LIST_TAB_MASTER",
    zoneMasterKey: "ZONE_MASTER",
    zoneTabMasterKey: "ZONE_TAB_MASTER",
    subZoneTabMasterKey: "SUBZONE_TAB_MASTER",
    approvalMasterKey: "APPROVAL_MASTER",
    planCreationKey: "PLAN_CREATION",
    claimCreationKey: "CLAIM_CREATION",
    attendanceReportKey: "ATTENDANCE_REPORT",
    travelReportKey: "TRAVEL_REPORT",
    ifoodsPlanReportKey: "PLAN_REPORT",
    outletVisitReportKey: "OUTLET_VISIT_REPORT",
    primaryCustomerVisitReportKey: "PRIMARY_CUSTOMER_VISIT_REPORT",
    secondaryOutletVisitReportKey: "SECONDARY_OUTLET_VISIT_REPORT",
    prospectReportKey: "PROSPECT_REPORT",
    primeCustReportKey: "PRIMARY_CUSTOMER_REPORT",
    competitorReportKey: "COMPETITOR_REPORT",
    secondCustReportKey: "SECONDARY_CUSTOMER_REPORT",
    mapViewReportKey: "MAP_VIEW",
    planDeviationReportKey: "PLAN_DEVIATION_REPORT",
    claimDetailsReportKey: "CLAIM_DETAILS_REPORT",
    claimExpenseReportKey: "CLAIM_EXPENSE_REPORT",
    travelClaimReportKey: "TRAVEL_CLAIM_REPORT",
    secondaryOrderReportKey: "SECONDARY_ORDER_REPORT",
    pjpReportKey: "PJP_REPORT",
    primaryOrderReportKey: "PRIMARY_ORDER_REPORT",
    venueMappingReportKey: "VENUE_MAPPING_REPORT",
    marketPromotionReportKey: "MARKET_PROMOTION_REPORT",
    settingsKey: "SETTINGS",
    notificationSettingsTabKey: "NOTIFICATION_SETTINGS_TAB",
    commonNotificationKey: "COMMON_NOTIFICATION",
    calendarEventKey: "CALENDAR_EVENT",
    primeMatMappingKey: "PRIMARY_MATERIAL_MAPPING",
    foodsDashboardKey: "FOODS_DASHBOARD",
    ifoodsDashboardKey: "IFOODS_DASHBOARD",
    eventMasterKey: "EVENT_MASTER",
    approvalClaimManagerKey: "CLAIM_VALIDATION_MANAGER",
    claimFrequencyMasterKey: "CLAIM_FREQUENCY_MASTER",
    approvalSecCustomerKey: "APPROVAL_SECONDARY_CUSTOMER",
}

export const requestedFeaturesAll = {
    createKey:  "CREATE",
    readKey: "READ",
    updateKey: "UPDATE",
    deleteKey: "DELETE",
};