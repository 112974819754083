import React from "react";
import TableView from "@/common/components/TableView/TableView";
import { Grid, Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { columns, reportFileName } from "./constants/constants";
import CreateEditEventActivity from 
"./create-edit-event-activity/create-edit-event-activity";
import ModalDialog from "@/common/components/modal-dialog/modal-dialog";
import useEventActivity from "./hooks/useEventActivity";
import { FormattedMessage } from "react-intl";
import useOnActions from "./hooks/useOnActions";
import AlertDialog from "@/common/components/alert-dialog/alert-dialog";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { isUserHasAccess } from "@/from-theme/utils/route-guard/helpers/helpers";
import useAuth from "@/from-theme/hooks/useAuth";

const {subZoneTabMasterKey} = requestedMenuAll;
const {createKey, updateKey, deleteKey} = requestedFeaturesAll;

export default function EventActivity() {
    const { subzoneMasterData, isCreateEditModalOpen, setIsCreateEditModalOpen, onRowEdit,
        activeEditId, closeModal, subzoneMasterStatus }
        = useEventActivity();
    const { userData } = useAuth();
    const entitlement = userData?.entitlement || {};
    
    const isUpdateDeleteAccess = isUserHasAccess(subZoneTabMasterKey, updateKey, entitlement)
        || isUserHasAccess(subZoneTabMasterKey, deleteKey, entitlement);
    const {
        onRowDelete,
        deleteSubzoneMasterStatus,
        isAlertDialog,
        onCloseAlert,
        onAgreeAlert
    } = useOnActions();
    const actionButton = (
    <VerifyAccess requestedMenu={subZoneTabMasterKey} requestedFeature={createKey}>
        <Button
            variant="contained"
            startIcon={<PlusOutlined />}
            onClick={() => setIsCreateEditModalOpen(true)}
        ><FormattedMessage id="add-event-activity" />
        </Button>
        </VerifyAccess>
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableView
                    data={subzoneMasterData}
                    columns={columns({ onRowEdit, onRowDelete, deleteSubzoneMasterStatus,
                        isUpdateDeleteAccess })}
                    actionButton={actionButton}
                    reportFileName={reportFileName}
                    apiStatus={subzoneMasterStatus}
                    stringInExport={["activeStatus"]}
                />
                {isCreateEditModalOpen && (
                    <ModalDialog
                        closeModal={() => closeModal()}
                        modalTitle={!activeEditId ? "create-activity-event" :
                         "edit-activity-event"}
                    >
                        <CreateEditEventActivity closeModal={() => closeModal()}
                            activeEditId={activeEditId} />
                    </ModalDialog>
                )}
                {isAlertDialog && (
                    <AlertDialog onCloseAlert={onCloseAlert} onAgreeAlert={onAgreeAlert} />
                )}
            </Grid>
        </Grid>
    );
}
