import { Chip, Grid } from "@mui/material";
import EditAction from "../components/edit-action/edit-action";
import DeleteAction from "../components/delete-action/delete-action";
import VerifyAccess from "@/common/components/verify-access/verify-access";
import { requestedMenuAll, requestedFeaturesAll } from "@/from-theme/routes/constants/menuAccess";
import { dateString } from "@/common/components/TableView/helpers/helpers";
import SeeMore from "../components/see-more/see-more";
const {primaryCustTabMasterKey} = requestedMenuAll;
const {updateKey, deleteKey} = requestedFeaturesAll;

export const columns = ({onRowEdit, onRowDelete, deletePrimaryCustomerMasterStatus,
  isUpdateDeleteAccess}) => ([...[
  {
    header: 's-no',
    accessorKey: 'fdPrimeCusId',
    cell: (value) => {
      const fdPrimeCusId = value.row.index + 1;
     return fdPrimeCusId;
    }
  },
  {
    header: 'key-id',
    accessorKey: 'fdPrimeCusId',
  },
  {
    header: 'sap-code',
    accessorKey: 'primeCusSapCode'
  },
  {
    header: 'customer-name',
    accessorKey: 'primeCusName'
  },
  {
    header: 'secondary-customer-name',
    accessorKey: 'fdSecCusName',
    cell: (cell) => {
      const content = cell.getValue() || "";
      const value = "Secondary Customer Name";
      const truncatedContent = content.slice(0, 30);
      const hasMore = content.length > 50;
      return (
          <div>
              {truncatedContent}
              {hasMore && (
                  <SeeMore content={content} value={value}  />
              )}
          </div>
      );
  }
  },
  {
    header: 'customer-category',
    hideColumn: true,
    accessorKey: 'primeCusCategory'
  },
  {
    header: 'customer-gst',
    hideColumn: true,
    accessorKey: 'primeCusGstNo'
  },
  {
    header: 'contact-number',
    accessorKey: 'contactNo'
  },
  {
    header: 'sub-zone',
    hideColumn: true,
    accessorKey: 'subZoneName'
  },
  {
    header: 'district',
    accessorKey: 'sapDistrictName'
  },
  {
    header: 'city',
    accessorKey: 'cityName'
  },
  {
    header: 'latitude',
    hideColumn: true,
    accessorKey: 'latitude'
  },
  {
    header: 'longitude',
    hideColumn: true,
    accessorKey: 'longitude'
  },
  {
    header: 'image',
    hideColumn: true,
    accessorKey: 'image'
  },
  {
    header: 'address',
    hideColumn: true,
    accessorKey: 'address'
  },
  {
    header: 'landmark',
    hideColumn: true,
    accessorKey: 'landmark'
  },
  {
    header: 'secondary-customer',
    hideColumn: true,
    accessorKey: 'fdSecCusName'
  },
  {
    header: 'pincode',
    hideColumn: true,
    accessorKey: 'pincode'
  },
  {
    header: 'created-at',
    hideColumn: true,
    accessorKey: 'createdAt',
    cell: ({ getValue }) => dateString(getValue()) 
  },
  {
    header: 'created-by',
    hideColumn: true,
    accessorKey: 'createdBy'
  },
  {
    header: 'updated-at',
    hideColumn: true,
    accessorKey: 'updatedAt',
    cell: ({ getValue }) => dateString(getValue()) 
  },
  {
    header: 'updated-by',
    hideColumn: true,
    accessorKey: 'updatedBy'
  },
  {
    header: 'status',
    accessorKey: 'activeStatus',
    cell: (cell) => {
      switch (cell.getValue()) {
        case '0':
          return <Chip color="error" label="In-Active" size="small" variant="light" />;
        case '1':
          return <Chip color="success" label="Active" size="small" variant="light" />;
      }
    }
  }
], ...(isUpdateDeleteAccess ? [
  {
    header: 'action',
    id: "edit",
    cell: (props) => (
      <Grid container className="table-icon-actions-2">
        <VerifyAccess requestedMenu={primaryCustTabMasterKey} requestedFeature={updateKey}>
        <Grid item>
          <EditAction
            {...props}
            onRowEdit={onRowEdit}
          />
        </Grid>
        </VerifyAccess>
        <VerifyAccess requestedMenu={primaryCustTabMasterKey} requestedFeature={deleteKey}>
        <Grid item>
          <DeleteAction
            {...props}
            onRowDelete={onRowDelete} 
            deletePrimaryCustomerMasterStatus={deletePrimaryCustomerMasterStatus}
          />
        </Grid>
        </VerifyAccess>

      </Grid>
    ),
    meta: {
      className: 'cell-center'
    }
  }

]:[])]);

export const initialValues = {
  stateId: null,
  districtId: null,
  cityId: null,
}

export const reportFileName = "Primary Customer Master";
