import TabsView from "@/common/components/tabs-view/tabs-view";
import React from "react";
import { eventMasterTabs } from "./constants/constants";

export default function EventMaster() {
    return(
        <TabsView
            tabs={eventMasterTabs}
        />
    )
}